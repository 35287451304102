<template>
  <div>
    <resume-upload ref="resume-upload" v-show="butType == 5 || butType == 6" :upDateType="'65185'" :uploadNum="uploadNum" :upFileSize="upFileSize" :allowFiles="allowFiles"
                   :fileType="fileType" :disabledBut="inputDisable"
                   @upFileDate="(data)=>getUpData(data)"></resume-upload>
    <div class="text_right" v-if="butType != 5 && butType != 6">
      <el-button icon="el-icon-download" size="small" @click="bulkExport()">
        批量下载
      </el-button>
    </div>
    <el-table
        :height="(butType == 5 || butType == 6) ? 'calc(100vh - 460px)' : 'calc(100vh - 300px)'"
        :data="proveDataList"
        size="small"
        v-loading="loading"
        ref="multipleTable"
        class="table"
        row-key="id"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column :reserve-selection="true" type="selection" width="50"/>
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200px"></el-table-column>
      <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fileSize" label="文件大小" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ (scope.row.fileSize / 1024 / 1024).toFixed(2) }}M</div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" label="上传时间" show-overflow-tooltip></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div v-if="butType == 5 && !inputDisable || butType == 6 && !inputDisable">
            <el-button size="mini" type="text" @click="deteliFile(scope.row,scope.$index)">删除
            </el-button>
          </div>
          <div v-if="butType != 5 && butType != 6">
            <el-button size="mini" type="text" @click="previewPdf(scope.row.netUrl)">预览</el-button>
            <el-button size="mini" type="text" @click="downFiile(scope.row.netUrl,scope.row.fileName)">下载</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ResumeUpload from "@/components/Upload/resumeUpload.vue";
import {Base64} from "js-base64";

export default {
  name: "proves",
  components: {ResumeUpload},
  props: {
    //藏品id
    id: {
      type: String,
      default: '',
    },

    //typePage：审核页面的标识(通过和驳回时用)
    typePage: {
      type: String,
      default: '',
    },

    //butType：证明资料只有在征集新增和编辑的时候能改5和6
    butType: {
      type: String,
      default: '',
    },

    //inputDisable：是否禁用上传框
    inputDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadNum: 10,
      upFileSize: 100,
      allowFiles: ['PDF'],
      fileType: 7,

      loading:false,
      proveDataList: [],
      dataListSelect: [],
    }
  },

  methods: {
    getPicture(dividePage) {
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      this.$axios(this.api.collection.listZmByDataId, {
        dataId: this.id,
      }, 'get').then((data) => {
        if (data.status) {
          this.loading = false
          this.proveDataList = data.data
        }
      })
    },

    //预览
    previewPdf(url) {
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + encodeURIComponent(Base64.encode(url)));
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    getUpData(data) {
      this.proveDataList = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
    },

    //删除文件
    deteliFile(row, index) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.proveDataList.splice(index, 1)
        this.$refs["resume-upload"].deleteFile(row.fileID ? row.fileID : row.id)
      }).catch(() => {
      })
    },

    //批量下载
    bulkExport(){
      if (this.dataListSelect.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.dataListSelect.map(item => {
        if (item) {
          return item.id
        }
      })

      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.collection.urlZmZip, {
        ids,
      }, 'get','blob').then((res) => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        let filename = '证明资料';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },

    downFiile(url, name) {
      const xhr = new XMLHttpRequest();
      // 使用open()方法初始化一个请求，第一个参数为请求的类型，第二个参数为请求的地址，第三个参数为是否异步
      xhr.open('GET', url, true);
      // 设置响应的数据类型
      xhr.responseType = 'blob';
      // 当请求加载完成时，触发onload事件
      xhr.onload = () => {
        // 如果请求的状态码为200，表示请求成功
        if (xhr.status === 200) {
          // 创建一个blob对象，第一个参数为响应的数据，第二个参数为blob对象的类型
          const blob = new Blob([xhr.response], {type: xhr.getResponseHeader('content-type')});
          // 创建一个a标签
          const link = document.createElement('a');
          // 为a标签设置href属性，值为blob对象的URL
          link.href = URL.createObjectURL(blob);
          // 为a标签设置下载文件名
          link.download = name;
          // 点击a标签，开始下载文件
          link.click();
        }
      };
      // 发送请求
      xhr.send();
    },
  },
}
</script>

<style scoped>

</style>